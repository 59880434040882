#navbar {
    overflow: hidden; /* no overflow to the right */
    padding: 20px 50px;
    box-shadow: 0 1px 8px #ddd; 
    background: #fff;
    font-family: 'Roboto', sans-serif;
    position: sticky;
    top: 0;
    z-index: 99;
}

.topnav {
    display: flex;
    justify-content: flex-end; /* move all elements to the right */
    align-items: center; /* move all elements to the center of column */
    text-decoration: none;
    font-size: 1em;
    color: #333;
}

#navbar span#title {
    margin-right: auto; /* this is to move title to the left side */
    font-size: 1.7em;
}

#navbar a {
    color: #333;
    text-decoration: none;
    padding: 0 20px;
    transition: all 0.5s ease 0s;
}

#navbar a:hover {
    color: #888;
    cursor: pointer;

}

/* #navbar a#icon {
    display: none;
} */

/* @media screen and (max-width: 600px) {
    #navbar .topnav {
        margin: auto;
        padding: 20px 5px;
        background: #555;
        color: #f2f2f2;
    } */

    /* #navbar #my-links {
        display: none;
    }

    #navbar a#icon {
        display: inline-block;
    } */


/* } */
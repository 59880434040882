* {
    margin: 0;
}

.Covid {
    display: block; 
    padding: 50px 30px;
    height: auto;
    font-family: 'Roboto', sans-serif;
    scroll-margin-top: 100px;
}

.covid-wrapper {
    padding: 0 20px;
}

.title-covid {
    text-align: center;
    padding: 10px 0;
}

.title-covid h3 {
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 700;
}

.title-covid h3 a {
    color: black;
    transition: all 0.5s ease-in ;
}

.title-covid h3 a:hover {
    color: rgba(141, 13, 13, 0.699);
}

.description-covid {
    text-align: center;
}

.title-covid p {
    color: #777;
}

.software {
    padding: 30px 0;
}

.sw {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
    padding: 25px;
    /* text-align: center; */
    place-items: center;
}


.sw li img.programming {
    width: 100px;
    height: auto;
    box-shadow: none;
}

.diagram h3, .diagram h4{
    padding: 20px 0;
}

.diagram .blueprint {
    display: grid;
    grid-template-columns: auto;
    place-items: center;
    padding: 20px;
}
.diagram img {
    width: 50%;
}


.bp-content {
    padding: 0 30px;
}
.bp-list {
    display: grid;
    list-style-type: disc;
}

.bp-list ul{
    display: grid;
    list-style-type: circle;
}

.bp-list ul ul {
    list-style-type: lower-alpha;
    padding-left: 20px;
}

.bp-dashboard {
    padding: 20px;
    display: grid;
    place-items: center;
}

.bp-dashboard img {
    width: 30%;
}

.covid-app {
    padding: 20px 0;
}

.covid-dashboard {
    display: grid; 
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    place-items: center;
    padding: 30px 0;
}

.covid-dashboard img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .diagram .blueprint img, .bp-dashboard img {
        width: 100%;
    }

    .covid-dashboard {
        grid-template-columns: auto auto;
    }

    .covid-dashboard img {
        height: 100%;
    }
}

@media screen and (max-width: 600px) {
    .sw {
        grid-template-columns: auto auto;
    }
    .bp-content {
        padding: 0 20px;
    }
    
    .covid-dashboard {
        grid-template-columns: auto;
    }
}
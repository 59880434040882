
#Portfolio {
    display: block;
    height: auto;
    font-family: 'Roboto', sans-serif;
    background: #f2f2f2;
    margin-top: -8px;
}

.wrapper {
    padding: 20px 20px;
}

#title-port {
    text-align: center;
}

#title-port h3 {
    font-size: 2em;
    font-weight: 700;
}

#title-port p{
    padding: 10px;
    color: #777;
}

.inside-wrap {
    margin: 10px;
}



#grid-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 2%;
    padding-top: 15px;
}

.item {
    /* min-height: 120px;
    overflow: auto; */
    box-shadow:
  0  5px 10px rgba(154,160,185,0.05),
  0 15px 40px rgba(166,173,201,0.2);
}

.item img {
    /* overflow: hidden; */
    width: 100%;
    height: 300px;
    object-fit: cover;

    
}

.item .title-desc {
    padding: 10px;
}

.item .item-desc {
    padding: 12px 16px;
    word-wrap: break-word;
    
}

.item-desc p {
    color: #777;
    word-wrap: break-word;
}

.item a {
    /* transition: all 0.5s ease;
    opacity: 0.5;
    background-color: black; */

    -webkit-filter: brightness(100%);
}

.item .a_hidden {
    display: none;
}

.item a:hover{
    cursor: pointer;
    /* opacity: 1; */
    -webkit-filter: brightness(70%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


#Portfolio footer {
    padding: 20px 0;
    
}

@media screen and (max-width: 1440px) {
    .item img {
        height: 250px;
    }
}

@media screen and (max-width: 1024px) {
    #grid-group {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 768px) {
    #grid-group {
        grid-template-columns: auto;
        padding: 30px;
    }

    .item img {
        height: 300px;
    }

    .item .a_hidden:hover {
        color: #555;
        
    }


}

@media screen and (max-width: 600px) {
    .item img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
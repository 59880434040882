#Contact {
    display: block;
    padding: 50px 0;
    height: auto;
    font-family: 'Roboto', sans-serif;
    scroll-margin-top: 100px;

}

.wrap-contact {
    padding: 0 20px;
}

#title-contact {
    text-align: center;
}

#title-contact h3 {
    font-size: 2em;
    font-weight: 700;
}

#title-contact p{
    padding: 10px;
    color: #777;
}

.inside-contact {
    margin: 10px;
}

.form {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding-top: 15px;
    grid-gap: 20px;
    
}

input, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical
}

button {
    background-color: #6b6767;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

button:hover {
    background-color: #45a049;
}

@media screen and (max-width: 768px) {
    .form {
        grid-template-columns: 50px 2fr 50px;
    }
}

@media screen and (max-width: 600px) {
    .form {
        grid-template-columns: 10px 1fr 10px;
    }
}
#Biography {
    display: grid;
    grid-template-columns: 100px 1fr 1fr 50px;
    grid-gap: 50px;
    font-family: 'Roboto', sans-serif;
    scroll-margin-top: 100px;

}

.content {
    padding: 50px 0;
}


.typing-demo {
    display: block;
}

.typing {
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.8em;
    font-weight: 700;
    width: 0;
    border-right: 1px solid;
    animation: typing steps(40, end) 3s forwards, 
                blink 0.75s infinite;
    
}

@keyframes typing {
    from {
      width: 0
    }
    to {
        width: 100%
    }
  }
      
@keyframes blink {
from { border-color: transparent }
to { border-color: black; }
}

#img {
    display: flex;
    align-items: center;
    justify-content: center;
}

img {
    width: 70%;
    height: auto;
    border-radius: 5px;
    box-shadow: 3px 3px 2px #ddd;
} 

.bio, #content-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 30px;
}

#content-header {
    margin-left: 10px;
    line-height: 1cm;
}

#content-header #description {
    color: #666;
}

ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
}

ul li {
    margin: 10px;
    filter: grayscale(0.5);
    transition: all 0.3s ease-in;
    
}

ul li:hover {
    filter: grayscale(0);
}



@media screen and (max-width: 1444px) {
    img {
        width: 80%;
    }
}

@media screen and (max-width: 1400px) {
    #Biography {
        grid-template-columns: 5% 30% 50% 15%;
    }

    .typing {
        white-space: inherit;
        animation: none;
        width: 100%;
        border: none;
        overflow: auto;
    }
}


@media screen and (max-width: 1024px) {
    #Biography {
        display: block;
        text-align: center;
    }

    .typing {
        animation: none;
        width: 100%;
        border: none;
    }

    img {
        width: 30%;
        height: auto;
        border-radius: 5px;
        box-shadow: 3px 3px 2px #ddd;
    } 
    ul {
        justify-content: center;
        /* padding-right: 10px; */
    }
    .bio, #content-header {
        margin: 10px;
    }

}
@media screen and (max-width: 768px) {
    #Biography {
        display: block;
        text-align: center;
    }

    .content {
        padding: 20px;
    }

    .bio, #content-header {
        margin: 10px;
    }

    #img {
        display: grid ;
        place-items: center;
    }
    ul {
        justify-content: center;
        /* padding-right: 10px; */
    }
}
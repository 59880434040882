@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');* {
    margin: 0;
    padding: 0;
    
}

/* background color: f2f2f2 */

#Landing {
    height: auto;
    background: #f2f2f2;
    
}


footer {
    display: flex;
    padding: 10px 0;
    height: 50px;
    font-family: 'Roboto', sans-serif;
    background: #555;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    color: #f2f2f2;
}

@media screen and (max-width: 500px) {
    footer {
        font-size: 0.9em;
    }
}

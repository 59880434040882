#Services {
    display: block;
    text-align: center;
    padding: 50px 0;
    height: auto;
    font-family: 'Roboto', sans-serif;
    scroll-margin-top: 100px;

}

#wrap {
    height: 100%;
}
#title {
    font-size: 2em;
    font-weight: 700;
}

.cont {
    margin: 10px;
}

.icon {
    padding: 10px;
}

#grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 5%;
}

.grid-item {
    padding: 10px;
}
.grid-item img {
    width: 20%;
}

.grid-item h3 {
    padding: 20px;
}

.grid-item p, #subtitle {
    color: #777;
}

@media screen and (max-width: 768px) {
    #grid {
        grid-template-columns: 1fr;
    }

}